<template>
  <div class="default-state">
    <SidebarDirectionList />
  </div>
</template>

<script lang="js">
import SidebarDirectionList from '@/components/sidebar/SidebarDirectionList.vue';

export default {
  name: 'SidebarIndex',
  components: {
    SidebarDirectionList
  }
}
</script>

<style lang="scss" scoped>
.default-state {
  padding: 0 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
