import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import entity from '@/helpers/FetchEntities';

Vue.use(VueRouter);

const routes = [
  {
    path: '/partners',
    component: () => import('@/components/sidebar/SidebarIndex.vue')
  },
  {
    path: '/objects',
    component: () => import('@/components/sidebar/SidebarIndex.vue')
  },
  {
    path: '/partners/search',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/objects/search',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/partners/filters',
    component: () => import('@/components/sidebar/filter/SidebarFilters.vue')
  },
  {
    path: '/objects/filters',
    component: () => import('@/components/sidebar/filter/SidebarFilters.vue')
  },
  {
    path: '/partners/filter/results',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/objects/filter/results',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/partners/:id',
    component: () => import('@/components/card/CardEntity.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/objects/:id',
    component: () => import('@/components/card/CardEntity.vue'),
    meta: {
      hasScroll: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Регулярные выражения для маршрутов
const partnersPath = /^\/partners(?:\/(?=$))?$/i;
const objectsPath = /^\/objects(?:\/(?=$))?$/i;
const partnersSearchPath = /^\/partners\/search(?:\/(?=$))?$/i;
const objectsSearchPath = /^\/objects\/search(?:\/(?=$))?$/i;
const partnersWithId = /^\/partners\/((?:[^/]+?))(?:\/(?=$))?$/i;
const objectsWithId = /^\/objects\/((?:[^/]+?))(?:\/(?=$))?$/i;

// Пути фильтров
const filterPaths = [
  '/partners/filters',
  '/objects/filters',
  '/partners/filter/results',
  '/objects/filter/results'
];

// Определение слоя
const layerDefinition = (to, from) => {
  if (to.path === '/' && from.path === '/') {
    router.replace('/partners');
  } else if (from.path === '/') {
    let layer = 'partners';

    if (to.path.includes('objects')) {
      layer = 'objects';
    }

    store.dispatch('layer/setLayer', layer);
  }
};

// Открытие карточки сущности
const openingCard = async (to, from) => {
  if (from.path === '/' && (partnersWithId.test(to.path) || objectsWithId.test(to.path)) && to.params.id) {
    await entity.list();
  }

  if ((partnersWithId.test(to.path) || objectsWithId.test(to.path)) && to.params.id) {
    console.log('Открытие карточки сущности:', to.params.id);
  }
};

// Загрузка списка сущностей
const loadingEntities = async (to, from) => {
  if (partnersPath.test(to.path) || objectsPath.test(to.path)) {
    await entity.list();
  }
}

// Перед каждым переходом на новый маршрут
router.beforeEach(async (to, from, next) => {
  // Определение слоя
  layerDefinition(to, from);

  // Открытие карточки сущности
  await openingCard(to, from);

  // Загрузка списка сущностей
  await loadingEntities(to, from);

  const isDetailPath = partnersWithId.test(to.path) || objectsWithId.test(to.path);

  // Очистка фильтров
  if (!filterPaths.includes(to.path)) {
    if (isDetailPath) {
      if (to.params.id === undefined) {
        store.dispatch('filter/clearFilter');
      }
    } else {
      store.dispatch('filter/clearFilter');
    }
  }

  // Очистка поиска
  const isSearchPath = partnersSearchPath.test(to.path) || objectsSearchPath.test(to.path);

  if (!isSearchPath) {
    if (isDetailPath) {
      if (to.params.id === undefined) {
        store.dispatch('search/clearQuery');
      }
    } else {
      store.dispatch('search/clearQuery');
    }
  }

  next(); // Переход на новый маршрут
});

export default router;
