import partners from './modules/partners';
import objects from './modules/objects';
import filters from './modules/filters';
import user from './modules/user';

const api = {
    partners,
    objects,
    filters,
    user
};

export default api;
