const state = {
    id: null,
    count: 0,
    directions: [],
    partnerTypes: [],
    objectTypes: [],
    partnerAttributes: [],
    productGroups: [],
    years: {},
    selectedDirections: [],
    selectedPartnerTypes: [],
    selectedObjectTypes: [],
    selectedPartnerAttributes: [],
    selectedProducts: [],
    selectedYears: {},
    selectedCertified: null
};

const actions = {
    setFilterId({ commit }, id) {
        commit('SET_ID', id);
    },
    setCount({ commit }, count) {
        commit('SET_COUNT', count);
    },
    setDirections({ commit }, directions) {
        commit('SET_DIRECTIONS', directions);
    },
    setPartnerTypes({ commit }, partnersTypes) {
        commit('SET_PARTNER_TYPES', partnersTypes);
    },
    setObjectTypes({ commit }, objectTypes) {
        commit('SET_OBJECT_TYPES', objectTypes);
    },
    setPartnerAttributes({ commit }, partnerAttributes) {
        commit('SET_PARTNER_ATTRIBUTES', partnerAttributes);
    },
    setProductGroups({ commit }, productGroups) {
        commit('SET_PRODUCT_GROUPS', productGroups);
    },
    setYears({ commit }, years) {
      commit('SET_YEARS', years);
    },
    selectDirection({ commit }, directions) {
      commit('SYNC_PROPERTIES_WITH_DIRECTIONS', directions);
      commit('SELECT_DIRECTION', directions);
    },
    selectPartnerType({ commit }, items) {
        commit('SELECT_PARTNER_TYPE', items);
    },
    selectObjectType({ commit }, items) {
        commit('SELECT_OBJECT_TYPE', items);
    },
    selectPartnerAttribute({ commit }, items) {
        commit('SELECT_PARTNER_ATTRIBUTE', items);
    },
    selectProduct({ commit }, items) {
        commit('SELECT_PRODUCT', items);
    },
    selectYears({ commit }, years) {
      commit('SELECT_YEARS', years);
    },
    selectCertified({ commit }, isCertified) {
        commit('SELECT_CERTIFIED', isCertified);
    },
    clearFilter({ commit }) {
        commit('SET_ID', null);
        commit('SELECT_DIRECTION', []);
        commit('SELECT_PARTNER_TYPE', []);
        commit('SELECT_OBJECT_TYPE', []);
        commit('SELECT_PARTNER_ATTRIBUTE', []);
        commit('SELECT_PRODUCT', []);
        commit('SELECT_YEARS', {});
        commit('SET_COUNT', 0);
        commit('SELECT_CERTIFIED', null);
    }
};

const mutations = {
    SET_ID(state, id) {
        state.id = id;
    },
    SET_COUNT(state, count) {
        state.count = count;
    },
    SET_DIRECTIONS(state, items) {
        state.directions = items;
    },
    SET_PARTNER_TYPES(state, items) {
        state.partnerTypes = items;
    },
    SET_OBJECT_TYPES(state, items) {
        state.objectTypes = items;
    },
    SET_PARTNER_ATTRIBUTES(state, items) {
        state.partnerAttributes = items;
    },
    SET_PRODUCT_GROUPS(state, items) {
      state.productGroups = items;
    },
    SET_YEARS(state, years) {
      state.years = years;
    },
    SELECT_DIRECTION(state, items) {
        state.selectedDirections = items;
    },
    SELECT_PARTNER_TYPE(state, items) {
        state.selectedPartnerTypes = items;
    },
    SELECT_OBJECT_TYPE(state, items) {
        state.selectedObjectTypes = items;
    },
    SELECT_PARTNER_ATTRIBUTE(state, items) {
        state.selectedPartnerAttributes = items;
    },
    SELECT_PRODUCT(state, items) {
        state.selectedProducts = items;
    },
    SELECT_YEARS(state, years) {
      state.selectedYears = years;
    },
    SELECT_CERTIFIED(state, isCertified) {
      state.selectedCertified = isCertified;
    },
    SYNC_PROPERTIES_WITH_DIRECTIONS(state, directions) {
        if (directions.length > 0) {
            if (!Array.isArray(state.partnerTypes) || !Array.isArray(state.partnerAttributes) || !Array.isArray(state.productGroups)) {
                console.warn('One of the properties is not an array');
                return;
            }

            const partnerTypeMap = new Map(state.partnerTypes.map(type => [type.id, type]));
            const partnerAttributeMap = new Map(state.partnerAttributes.map(type => [type.id, type]));
            const productGroupMap = new Map(
                state.productGroups.map(group => [group.direction_id, group.products.map(product => product.id)])
            );

            state.selectedPartnerTypes = state.selectedPartnerTypes.filter(item => {
                const partnerType = partnerTypeMap.get(item);
                return partnerType && directions.includes(partnerType.direction_id);
            });

            state.selectedPartnerAttributes = state.selectedPartnerAttributes.filter(item => {
                const partnerAttribute = partnerAttributeMap.get(item);
                return partnerAttribute && directions.includes(partnerAttribute.direction_id);
            });

            state.selectedProducts = state.selectedProducts.filter(item => [...productGroupMap.keys()].some(direction => directions.includes(direction) && productGroupMap.get(direction).includes(item)));
        }
    }
};

const getters = {
    filterId: state => state.id,
    filterCount: state => state.count,
    filterValues: state => ({
        directions: state.selectedDirections.length ? state.selectedDirections.sort() : null,
        partnerTypes: state.selectedPartnerTypes.length ? state.selectedPartnerTypes.sort() : null,
        objectTypes: state.selectedObjectTypes.length ? state.selectedObjectTypes.sort() : null,
        partnerAttributes: state.selectedPartnerAttributes.length ? state.selectedPartnerAttributes.sort() : null,
        products: state.selectedProducts.length ? state.selectedProducts.sort() : null,
        years: state.selectedYears.start && state.selectedYears.end ? state.selectedYears : null,
        certified: state.selectedCertified
    }),
    directions: state => state.directions,
    partnerTypes: state => state.partnerTypes,
    objectTypes: state => state.objectTypes,
    partnerAttributes: state => state.partnerAttributes,
    productGroups: state => state.productGroups,
    years: state => state.years,
    selectedDirections: state => state.selectedDirections,
    selectedPartnerTypes: state => state.selectedPartnerTypes,
    selectedObjectTypes: state => state.selectedObjectTypes,
    selectedPartnerAttributes: state => state.selectedPartnerAttributes,
    selectedProducts: state => state.selectedProducts,
    selectedYears: state => state.selectedYears,
    selectedCertified: state => state.selectedCertified
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
