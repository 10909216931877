<template>
  <div class="page-content">
    <yandex-map :settings="settings" :controls="[]" :options="options" :coords="currentLocation.coords"
      :zoom="currentLocation.zoom" :cluster-options="clusterOptions" @map-was-initialized="initializeMap($event)"
      @boundschange="boundsChange()">
      <MapMarker v-for="marker in items" :key="marker.id + '_' + interactionKey" :item="marker"></MapMarker>
      <ymap-marker v-if="isDot" marker-id="dot" :icon="dot" :coords="currentLocation.coords"></ymap-marker>
    </yandex-map>
    <div class="map__controls">
      <div class="map__button-group">
        <span class="map__button" @click="increaseZoom()">
          <img src="@/assets/images/svg/plus.svg" alt="">
        </span>
        <hr>
        <span class="map__button" @click="decreaseZoom()">
          <img src="@/assets/images/svg/minus.svg" alt="">
        </span>
      </div>
      <div class="map__button map__button--block" @click="toUserLocation">
        <img src="@/assets/images/svg/arrow-location.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import MapMarker from '@/components/MapMarker.vue';
import createCluster from '@/helpers/ClusteringHelper';
import entity from '@/helpers/FetchEntities';

export default {
  name: 'MapComponent',
  components: {
    yandexMap,
    ymapMarker,
    MapMarker
  },
  data() {
    return {
      interactionKey: 0, // Ключ для обновления маркеров на карте
      settings: {
        apiKey: process.env.VUE_APP_MAP_KEY,
        lang: 'ru_RU'
      },
      options: {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      },
      clusterOptions: {
        objects: {
          gridSize: 512,
          createCluster: (e, obj) => createCluster(e, obj)
        },
        partners: {
          gridSize: 512,
          createCluster: (e, obj) => createCluster(e, obj)
        }
      },
      dot: {
        layout: 'default#image',
        imageHref: '/img/dot.svg',
        imageSize: [30, 30]
      },
      abortGetPartnersController: null
    }
  },
  watch: {
    items: {
      deep: true,
      handler() {
        if (this.map && this.map.geoObjects) {
          this.map.geoObjects.removeAll();
        }

        this.updateInteractionKey();
      }
    },
    currentLocation(newValue, oldValue) {
      try {
        if (this.map && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.map.setCenter(this.currentLocation.coords, this.currentLocation.zoom);
          setTimeout(() => {
            if (this.items.length === 0 && this.currentLocation.zoom > 11) {
              this.map.setCenter(this.currentLocation.coords, this.currentLocation.zoom - 1);
            }
          }, 0);
        }
      } catch (error) {
        this.$services.MessageService.error('Не удалось установить текущее местоположение');
        console.error('Не удалось установить текущее местоположение:', error);
      }
    }
  },
  created() {
    // Привязываем контекст debounced функции при создании компонента
    this.debouncedFetch = debounce(this.debouncedFetch.bind(this), 300);
  },
  beforeDestroy() {
    if (this.abortGetPartnersController) {
      this.abortGetPartnersController.abort();
    }
  },
  methods: {
    async initializeMap(event) {
      this.startLoading();
      this.setMap(event);
      this.stopLoading();
    },
    async boundsChange() {
      // Отложить вызов API на 300 мс после того, как изменения прекратились
      await this.debouncedFetch();
    },
    // Отложенный вызов API
    async debouncedFetch() {
      // Отменяем предыдущий запрос
      if (this.abortGetPartnersController) {
        this.abortGetPartnersController.abort();
      }

      // Создаём новый AbortController для текущего запроса
      this.abortGetPartnersController = new AbortController();
      const signal = this.abortGetPartnersController.signal;

      if (this.filterId === null && (this.searchQuery === null || this.searchQuery === '') && this.itemId === null) {
        await entity.list(signal, false);
      } else {
        if (this.filterId) {
          await entity.filter(signal);
        }

        if (this.searchQuery) {
          await entity.search(signal, false);
        }
      }
    },
    toUserLocation() {
      const successCallback = (position) => {
        const coords = [position.coords.latitude, position.coords.longitude];
        this.setCurrentLocation({ coords, zoom: 10 });
      };

      const errorCallback = (error) => {
        this.$services.MessageService.error('Отказано в доступе к геолокации');
        console.error('Отказано в доступе к геолокации:', error);
        this.setCurrentLocation({ coords: this.userLocation, zoom: 10 }); // если геолокация не получена, использовать userLocation
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else {
        this.setCurrentLocation({ coords: this.userLocation, zoom: 10 });
      }

      this.$store.dispatch('removeDot');
    },
    updateInteractionKey() {
      if (this.map && this.map.geoObjects) {
        this.map.geoObjects.removeAll();
      }
      this.interactionKey += 1;
    },
    ...mapActions('map', ['setMap', 'removeAllObjects', 'increaseZoom', 'decreaseZoom']),
    ...mapActions('entity', ['setEntities']),
    ...mapActions('location', ['setCurrentLocation']),
    ...mapActions('loader', ['startLoading', 'stopLoading']),
    ...mapActions('filter', ['setFilterId', 'setCount'])
  },
  computed: {
    activeLayer() {
      return this.layer;
    },
    isDot() {
      return this.$store.getters['getDot'];
    },
    ...mapGetters('entity', ['items', 'itemId']),
    ...mapGetters('map', ['map', 'bounds', 'zoom']),
    ...mapGetters('location', ['currentLocation', 'userLocation']),
    ...mapGetters('search', ['searchQuery']),
    ...mapGetters('layer', ['layer']),
    ...mapGetters('filter', ['filterId'])
  }
}
</script>

<style scoped lang="scss">
.page-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: grayscale(1);
  pointer-events: none;
  z-index: 1;
}

.ymap-container {
  width: 100%;
  height: 100%;
}

.map {
  &__controls {
    position: fixed;
    top: calc(50vh - 85px);
    right: 40px;

    @media (max-width: 767px) {
      top: 50vh;
      right: 10px;
    }

    @media (max-height: 499px) {
      top: 47vh;
    }
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50px;
    height: 100px;
    padding: 10px 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;

    hr {
      width: 100%;
      height: 1px;
      border: none;
      background-color: #BFBFBF;
    }

    @media (max-width: 767px) {
      width: 40px;
      height: 80px;
      padding: 8px;
      margin-bottom: 16px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;

      @media (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }

    &--block {
      width: 50px;
      height: 50px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
      }

      img {
        width: 25px;
        height: 25px;

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
