<template>
  <div class="card-wrapper__pic-wrap">
    <img :src="images[0].thumbnail_link" alt="" class="card-wrapper__pic" @click="gallery = 0">
    <span class="card-wrapper__pic-caption">{{ images.length }} фото</span>
    <Lightbox :index="gallery" :fullScreen="true" @close="gallery = null" thumbsPosition="bottom" effect="fade"
      :closeOnClickOutsideMobile="true" slideshowColorBar="#DC143C" :items="transformedImages">
      <template v-slot:icon-previous>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="34" viewBox="0 0 18 34" fill="none">
          <path
            d="M0.666676 17.0003C0.665609 16.4551 0.855484 15.9268 1.20334 15.507L12.87 1.50698C13.2661 1.03048 13.8352 0.730822 14.4522 0.673936C15.0692 0.61705 15.6835 0.807594 16.16 1.20365C16.6365 1.59971 16.9362 2.16883 16.9931 2.78583C17.0499 3.40282 16.8594 4.01714 16.4633 4.49365L6.01001 17.0003L16.09 29.507C16.2838 29.7457 16.4286 30.0203 16.5159 30.3151C16.6032 30.6099 16.6315 30.919 16.5989 31.2247C16.5664 31.5305 16.4738 31.8268 16.3264 32.0966C16.179 32.3664 15.9797 32.6044 15.74 32.797C15.5001 33.0107 15.2186 33.1725 14.9133 33.2724C14.6079 33.3722 14.2852 33.4079 13.9654 33.3772C13.6456 33.3466 13.3355 33.2502 13.0547 33.0941C12.7739 32.9381 12.5283 32.7257 12.3333 32.4703L1.06334 18.4703C0.770472 18.0385 0.63081 17.5209 0.666676 17.0003Z"
            fill="white" />
        </svg>
      </template>
      <template v-slot:icon-next>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="34" viewBox="0 0 18 34" fill="none">
          <path
            d="M17.3333 16.9997C17.3344 17.5449 17.1445 18.0732 16.7967 18.493L5.12999 32.493C4.73394 32.9695 4.16481 33.2692 3.54781 33.3261C2.93082 33.383 2.3165 33.1924 1.83999 32.7963C1.36349 32.4003 1.06383 31.8312 1.00694 31.2142C0.950058 30.5972 1.1406 29.9829 1.53666 29.5063L11.99 16.9997L1.90999 4.49302C1.71617 4.25434 1.57143 3.97972 1.48409 3.68493C1.39675 3.39014 1.36853 3.08099 1.40106 2.77526C1.43358 2.46953 1.52621 2.17323 1.67361 1.90341C1.82102 1.6336 2.0203 1.39557 2.25999 1.20301C2.49991 0.98933 2.78137 0.827492 3.08674 0.727642C3.39211 0.627792 3.71482 0.592087 4.03463 0.622753C4.35444 0.653419 4.66448 0.749802 4.94531 0.905861C5.22614 1.06192 5.47172 1.2743 5.66666 1.52969L16.9367 15.5297C17.2295 15.9615 17.3692 16.4791 17.3333 16.9997Z"
            fill="white" />
        </svg>
      </template>
    </Lightbox>
  </div>
</template>

<script lang="js">
import Lightbox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'CardImages',
  components: {
    Lightbox
  },
  props: {
    images: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      gallery: null
    };
  },
  computed: {
    transformedImages() {
      return this.images.map(img => ({
        src: img.web_link,
        thumb: img.thumbnail_link
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrapper__pic-wrap::after {
  content: url('../../assets/images/svg/looking-glass.svg');
}
</style>
