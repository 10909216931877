<template>
  <div :class="{ 'scrollbar': hasScrollbar }" class="sidebar">
    <div :class="{ 'shadow': hasShadow }" class="sidebar-menu">
      <SidebarHeader />
      <SidebarSearch :placeholder="placeholder" collapse-tags />
      <div class="text-block">
        <span>или</span>
        <span class="label">Искать по фильтрам</span>
      </div>
      <SidebarDirectionSelect />
    </div>
    <div class="sidebar-main" @scroll="toggleShadow($event)">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarHeader from '@/components/sidebar/SidebarHeader.vue';
import SidebarSearch from '@/components/sidebar/SidebarSearch.vue';
import SidebarDirectionSelect from '@/components/sidebar/SidebarDirectionSelect.vue';

export default {
  name: 'Sidebar',
  components: {
    SidebarHeader,
    SidebarSearch,
    SidebarDirectionSelect
  },
  data() {
    return {
      hasShadow: false,
      queryString: '',
      activeQuery: ''
    }
  },
  methods: {
    toggleShadow(event) {
      this.hasShadow = !!event.target.scrollTop
    }
  },
  computed: {
    hasScrollbar() {
      return this.$route.meta.hasScrollbar
    },
    placeholder() {
      return `Искать по названию ${this.layer === 'partners' ? 'партнера' : 'объекта'}`;
    },
    ...mapGetters('layer', ['layer']),
    ...mapGetters('search', ['searchQuery']),
    ...mapGetters('filter', ['selectedDirections'])
  }
}
</script>

<style lang="scss">
.sidebar {
  position: relative;
  height: 100vh;
  width: 393px;
  background: #FFFFFF;
  box-shadow: 3px 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.sidebar-menu {
  padding: 0px 10px;
  padding-top: 20px;
}

.sidebar-main {
  flex-grow: 1;
  max-height: calc(100vh - 289px);
}

.scrollbar {
  margin-right: 5px;
  overflow-y: auto;
}

.sidebar-main::-webkit-scrollbar {
  width: 5px;
}

.sidebar-main::-webkit-scrollbar-thumb {
  background-color: black;
  margin-top: 42px;
}

.sidebar-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 26px;
}

.sidebar-search {
  position: relative;
}

.sidebar-search__tags {
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease;
}

.sidebar-search__tags.visible {
  opacity: 1;
  pointer-events: all;
  z-index: 5;
}

.sidebar-search__tags li {
  padding: 9px 20px 13px;
  background-color: #fff;
  color: #000;
  transition: background-color .3s ease, color .3s ease;
  cursor: pointer;
}

.sidebar-search__tags li:hover {
  background-color: #58BCAF;
  color: #fff;
}

.sidebar-search__tag {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.sidebar-search__address {
  display: block;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo {
  width: 140px;
  height: 45px;
  cursor: pointer;
}

.city {
  font-size: 18px;
  color: #DC143C;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
}

.sidebar-select {
  position: relative;
  display: flex;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.select-checkbox::after {
  content: '';
  position: absolute;
  top: 17px;
  left: 14px;
  width: 12px;
  height: 12px;
  background: url('../assets/images/svg/checked.svg') no-repeat;
  opacity: 0;
  z-index: 2;
}

.el-select-dropdown.is-multiple .__item.select-checkbox.selected::after {
  opacity: 1;
}

.setting-options {
  width: 46px;
  height: 42px;
  padding: 0;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  cursor: pointer;
  transition: background-color .5s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
  }
}

.setting-options.fill {
  background-color: #58BCAF;
  border-color: #58BCAF;
}

.options-icon {
  width: 26px;
  height: 26px;
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 18px;
  gap: 8px;
}

.text-block span {
  font-size: 16px;
  /* Размер текста для "или" */
  color: #8C8C8C;
  /* Тусклый цвет */
}

.text-block .label {
  font-size: 18px;
  color: #8C8C8C;
}

@media (min-width: 1024px) {
  .sidebar {
    overflow-y: hidden;
  }
}

@media (max-width: 1023px) {
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 1;
  }

  .sidebar-menu {
    padding-top: 10px;
  }

  .logo {
    width: 117px;
    height: 37px;
  }

  .sidebar-top {
    margin-bottom: 20px;
    align-items: flex-end;
  }

  .city {
    margin-bottom: 2px;
  }
}

@media (max-height: 499px) {
  .sidebar-top {
    display: none;
  }
}
</style>
