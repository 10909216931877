<template>
  <div class="card-contacts">
    <img src="@/assets/images/svg/calendar.svg" alt="" class="card-contacts__icon">
    <p class="card-contacts__text">Год сдачи объекта: {{ year }}</p>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardYearCompletion',
  props: {
    year: {
      type: Number,
      default: 0,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
