<template>
  <div class="product-list">
    <div v-for="item in direction" :key="item.name" class="product-list__item-wrap">
      <a class="product-list__item">
        <img class="product__logo" :style="`border-color: ${item.color}`"
          src="@/assets/images/svg/logo-small-transparent.svg" alt="">
        <p class="product-list__title">{{ item.name }}</p>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductList',
  computed: {
    direction() {
      // Собираем все направления в один массив и создаём карту уникальных элементов
      const uniqueItemsMap = new Map();

      this.items.flatMap(item => item.directions).forEach(direction => {
        const key = `${direction.code.toLowerCase()}-${direction.name}`;
        if (!uniqueItemsMap.has(key)) {
          uniqueItemsMap.set(key, {
            color: direction.color,
            name: direction.name,
            position: direction.position
          });
        }
      });

      // Преобразуем карту в массив, сортируем и возвращаем данные
      return Array.from(uniqueItemsMap.values()).sort((a, b) => a.position - b.position);
    },
    ...mapGetters('entity', ['items'])
  }
}
</script>

<style scoped>
.product-list {
  position: absolute;
  left: 30px;
  bottom: 30px;
  max-width: calc(100% - 100px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #FFFFFF;
}

.product-list__item {
  display: flex;
  align-items: center;
}

.product-list__item-wrap:not(:last-child) .product-list__item {
  margin-right: 30px;
}

.product-list__title {
  font-size: 16px;
  line-height: 14px;
  color: #000000;
}

.product__logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 6px;
  margin-right: 7px;
  border: 4px solid transparent;
}

.product__logo--green {
  border: 4px solid #58BCAF;
}

.product__logo--pink {
  border: 4px solid #DC143C;
}

.product__logo--black {
  border: 4px solid #000000;
}

.product__logo--gray {
  border: 4px solid #4E4E4E;
}

@media (max-width: 1440px) {
  .product-list {
    justify-content: flex-start;
  }

  .product-list__item {
    margin-bottom: 5px;
  }

  .product-list__item:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 1023px) {
  .product-list {
    display: flex;
    width: 100vw;
    left: 10px;
    bottom: 10px;
    padding: 0;
    overflow-x: auto;
    max-width: none;
    background-color: transparent;
  }

  .product-list__item-wrap {
    flex-shrink: 0;
  }

  .product-list__item {
    padding: 8px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 0;
  }

  .product__logo {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-width: 3px;
  }

  .product-list__item-wrap:not(:last-child) .product-list__item {
    margin-right: 8px;
  }

  .product-list__item-wrap:last-child {
    padding-right: 8px;
  }
}

@media (max-height: 499px) {
  .product-list {
    bottom: 5px;
  }
}
</style>
