<template>
  <div id="app">
    <Loader />
    <LayoutSidebar />
  </div>
</template>

<script>
import LayoutSidebar from '@/views/LayoutSidebar.vue';
import Loader from '@/components/Loader.vue'
import filter from '@/helpers/FetchFilters';
import user from '@/helpers/FetchUser';

export default {
  name: 'App',
  components: {
    LayoutSidebar,
    Loader
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await Promise.all([
        user.info(),
        filter.directionList(),
        filter.partnerTypeList(),
        filter.objectTypeList(),
        filter.partnerAttributeList(),
        filter.productGroupList(),
        filter.years(),
        this.$store.dispatch('fetchProducts'),
        this.$store.dispatch('fetchBusinessRegions'),
        this.$store.dispatch('fetchGeoRegions'),
        this.$store.dispatch('fetchSocials')
      ]);
    }
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  overflow: hidden;
}
</style>
