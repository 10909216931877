<template>
  <div class="card-description" v-if="description">
    <p class="card-title">{{ title }}</p>
    <p v-if="isHtml" class="card-title__small" v-html="formatDescription(description)"></p>
    <p v-else class="card-title__small">{{ description }}</p>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardDescription',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      required: true
    },
    isHtml: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatDescription(description) {
      if (description) {
        return description.replace(/\n/g, '<br>');
      }
      return '';
    }
  }
}
</script>

<style scoped></style>
