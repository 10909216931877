const state = {
    items: [],
    visited: [],
    item: null,
    itemId: null
};

const actions = {
    setEntities({ commit }, items) {
        commit('SET_ENTITIES', items);
    },
    setEntity({ commit }, item) {
        commit('SET_ENTITY', item);
    },
    setEntityId({ commit }, id) {
        commit('SET_ENTITY_ID', id);
    },
    setVisited({ commit }, id) {
        commit('SET_VISITED', id);
    }
};

const mutations = {
    SET_ENTITIES(state, items) {
        state.items = items;
        state.visible = [];
    },
    SET_ENTITY(state, item) {
        state.item = item;
    },
    SET_ENTITY_ID(state, id) {
        state.itemId = id;
    },
    SET_VISITED(state, id) {
        state.visited.push(id);
    }
};

const getters = {
    items: state => state.items,
    item: state => state.item,
    itemId: state => state.itemId,
    visited: state => state.visited
};

export default {
    namespaced: true, // Также используем namespace для модуля
    state,
    actions,
    mutations,
    getters
};
