<template>
  <div class="filters__range range" :class="{ 'collapse': collapse }">
    <div class="range__text">
      <input type="text" :value="range[0]" @input="onInput(0, $event.target.value)">
      <span>–</span>
      <input type="text" :value="range[1]" @input="onInput(1, $event.target.value)">
    </div>
    <div class="range__slider">
      <el-slider ref="slider" v-model="range" range :min="item.start" :max="item.end" :show-tooltip="false"
        @change="onChange"></el-slider>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'SidebarFilterGroupRange',
  props: {
    item: {
      type: Object,
      default: () => ({
        start: 0,
        end: 100
      })
    },
    selectedItem: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: Function,
      required: true
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      range: [this.item.start, this.item.end]
    }
  },
  mounted() {
    if (this.selectedItem.start && this.selectedItem.end) {
      this.range = [this.selectedItem.start, this.selectedItem.end];
    }
  },
  watch: {
    selectedItem(newValue) {
      if (!newValue.start || !newValue.end) {
        this.range = [this.item.start, this.item.end];
      }
    }
  },
  methods: {
    onChange(event) {
      this.action({ start: event[0], end: event[1] });
    },
    onInput(index, value) {
      if (value < this.item.start || value > this.item.end) {
        return;
      }
      this.range[index] = value;
      this.$refs.slider.setValues(this.range);
      const values = this.range.map(Number);
      this.action({ start: values[0], end: values[1] });
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  &__range {
    margin-top: 10px;
  }

  &__range.collapse {
    display: none;
  }
}

.range {
  &__text {
    margin-bottom: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;

    input {
      flex-grow: 1;
      padding: 10px;
      border: 1px solid #BFBFBF;
    }

    span {
      margin: 0 10px;
    }
  }

  &__slider {
    padding: 0 9px;
  }
}
</style>
