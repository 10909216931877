<template>
  <ul class="filters__options" :class="{ 'expand': localExpand, 'collapse': collapse }">
    <li v-for="item in items" :key="item.id">
      <input class="input-hidden" :id="item.id" name="cert" type="radio" v-model="localValue" :value="item.value"
        @click="action(item.value)" />
      <label :for="item.id" class="label-radio">{{ item.title }}</label>
    </li>
  </ul>
</template>

<script lang="js">
export default {
  name: 'SidebarFilterGroupRadioButtonList',
  data() {
    return {
      localExpand: this.expand,
      localValue: this.selectedValue
    };
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: Boolean,
      default: null
    },
    action: {
      type: Function,
      required: true
    },
    expand: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  &__options {
    margin-top: 10px;

    li:nth-child(n+5) {
      display: none;
    }
  }

  &__options.expand {
    li:nth-child(n+5) {
      display: block;
    }
  }

  &__options.collapse {
    display: none;
  }
}

.input-hidden {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.label-radio {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 5px 0 5px 29px;
  font-size: 18px;
  line-height: 20px;
  background-color: #fff;
  transition: background-color .3s ease;
  cursor: pointer;
}

.label-radio:hover {
  background-color: #E5E5E5;
}

.label-radio::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 19px;
  height: 19px;
  border: 1px solid #000000;
  border-radius: 50%;
  background-color: #fff;
}

input:checked+.label-radio::before {
  background-color: #000;
}
</style>
