<template>
  <div class="filters__group" v-if="items.length || item">
    <p class="filters__group-name" @click="toggleGroup()">
      {{ title }}
      <span class="filters__chevron" :class="{ 'active': isActiveChevronName, 'collapse': localCollapse }">
        <img v-if="!isActiveChevronName" src="@/assets/images/svg/chevron-down.svg" alt="">
        <img v-else src="@/assets/images/svg/chevron-down_white.svg" alt="">
      </span>
    </p>
    <p v-if="isActiveChevronName && !localCollapse" class="filters__btn" @click="clear()">Очистить</p>
    <SidebarFilterGroupCheckboxList v-if="type === 'checkbox'" :items="items" :action="action" :expand="expand"
      :collapse="localCollapse" :selectedItems="selectedItems" />
    <SidebarFilterGroupRange v-else-if="type === 'range'" :item="item" :selectedItem="selectedItem" :action="action"
      :collapse="localCollapse" />
    <SidebarFilterGroupRadioButtonList v-else-if="type === 'radio'" :items="items" :selectedValue="selectedValue"
      :action="action" :expand="expand" :collapse="localCollapse" />
  </div>
</template>

<script lang="js">
import SidebarFilterGroupCheckboxList from '@/components/sidebar/filter/SidebarFilterGroupCheckboxList.vue';
import SidebarFilterGroupRange from '@/components/sidebar/filter/SidebarFilterGroupRange.vue';
import SidebarFilterGroupRadioButtonList from './SidebarFilterGroupRadioButtonList.vue';

export default {
  name: 'SidebarFilterGroup',
  components: {
    SidebarFilterGroupCheckboxList,
    SidebarFilterGroupRange,
    SidebarFilterGroupRadioButtonList
  },
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object
    },
    items: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Object,
      default: () => ({})
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      default: null
    },
    action: {
      type: Function,
      default: () => ({}),
      required: true
    },
    expand: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      localCollapse: this.collapse
    };
  },
  computed: {
    isActiveChevronName() {
      if (this.selectedItem.start && this.selectedItem.end) {
        return true;
      }

      if (this.selectedValue !== null) {
        return true;
      }

      return this.selectedItems.filter(item => this.items.some(obj => obj.id === item)).length > 0;
    }
  },
  methods: {
    toggleGroup() {
      this.localCollapse = !this.localCollapse;
    },
    clear() {
      if (this.selectedItem.start && this.selectedItem.end) {
        this.action({});
      } else {
        const filteredItems = this.selectedItems.filter(item => !this.items.some(obj => obj.id === item));
        this.action(filteredItems);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  &__group {
    padding: 0 10px;
    margin-bottom: 20px;

    &-name {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      cursor: pointer;
    }
  }

  &__chevron {
    display: inline-flex;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    transition: background-color .5s ease;

    img {
      width: 12px;
      height: 8px;
      transform: rotate(0deg);
      transition: transform .3s ease;
    }
  }

  &__chevron.active {
    background-color: #58BCAF;
  }

  &__chevron.collapse {
    img {
      transform: rotate(-90deg);
    }
  }

  &__btn {
    font-size: 18px;
    line-height: 24px;
    color: #DC143C;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &--apply {
      color: #fff;
    }

    img {
      width: 8px;
      height: 12px;
      margin-left: 8px;
    }
  }
}
</style>
