import store from '@/store';

function handleMouseLeave(e) {
    e.get('target').getOverlaySync().getLayoutSync().getElement().children[0].classList.remove('cluster_hovered');
}

function handleMouseEnter(e) {
    e.get('target').getOverlaySync().getLayoutSync().getElement().children[0].classList.add('cluster_hovered');
}

function addPlacemarkEvents(clusterer) {
    const placemark = clusterer.getGeoObjects()[0];

    placemark.events.add(['click'], e => store.dispatch('map/zoomIn', e));
    placemark.events.add(['mouseenter'], e => handleMouseEnter(e));
    placemark.events.add(['mouseleave'], e => handleMouseLeave(e));
}

function createClusterer(center, pieChartOptions, objectIds) {
    const clusterer = new window.ymaps.Clusterer();
    const placemark = new window.ymaps.Placemark(
      center,
      {
        data: pieChartOptions,
        iconContent: objectIds ? objectIds.length : ''
      },
      {
        iconLayout: 'default#pieChart',
        iconPieChartRadius: window.innerWidth > 767 ? 35 : 25,
        iconPieChartCoreRadius: window.innerWidth > 767 ? 28 : 20,
        iconPieChartStrokeWidth: 0,
        hasBalloon: false
      }
    );
    clusterer.add(placemark);
    return clusterer;
}

function updatePieChartDirections(pieChartOptions, colors, objectIds) {
    colors.forEach(color => {
      const fItem = pieChartOptions.find(option => option.color === color);
      if (fItem) {
        fItem.weight++;
        fItem.objects = objectIds;
      }
    });
}

function getDirectionColors(item) {
    return Array.isArray(item.directions) ? [...new Set(item.directions.map(direction => direction.color))] : [];
}

function updatePieChartWeights(pieChartOptions, objectIds, items) {
  objectIds.forEach(obj => {
      const item = items.find(marker => marker.id === obj);

      if (item) {
        const directionColors = getDirectionColors(item);
        updatePieChartDirections(pieChartOptions, directionColors, objectIds);
      }
    });
}

function initializePieChartOptions() {
  return Object.values(store.getters['filter/directions']).map(item => ({ weight: 0, color: item.color }));
}

const createCluster = (center, objects) => {
  const objectIds = objects.map(el => el.properties.get('markerId'));
  const entities = store.getters['entity/items'];
  const pieChartOptions = initializePieChartOptions();

  updatePieChartWeights(pieChartOptions, objectIds, entities);

  const clusterer = createClusterer(center, pieChartOptions, objectIds);
  addPlacemarkEvents(clusterer);

  return clusterer;
}

export default createCluster;
