const state = {
    map: null
};

const actions = {
    setMap({ commit }, map) {
        commit('SET_MAP', map);
    },
    removeAllObjects({ commit }) {
        commit('REMOVE_ALL_OBJECTS');
    },
    increaseZoom({ commit }) {
        commit('INCREASE_ZOOM');
    },
    decreaseZoom({ commit }) {
        commit('DECREASE_ZOOM');
    },
    zoomIn({ commit }, event) {
      commit('ZOOM_IN', event);
    },
    async waitForBounds({ state }) {
      return new Promise((resolve, reject) => {
        const startTime = Date.now();
        const timeout = 10000; // 10 секунд
        const interval = 500; // 500 мс на проверку

        // Рекурсивная проверка наличия bounds
        async function checkBounds() {
          const bounds = state.map ? state.map.getBounds() : null // Пытаемся получить bounds
          if (bounds) {
            resolve(bounds);
            return;
          }

          if (Date.now() - startTime > timeout) {
            reject(new Error('Timeout: Bounds не были получены'));
            return;
          }

          // Ждём интервал и проверяем снова
          setTimeout(checkBounds, interval);
        }

        checkBounds();
      });
    }
};

const mutations = {
    SET_MAP(state, map) {
        state.map = map;
    },
    REMOVE_ALL_OBJECTS(state) {
        state.map.geoObjects.removeAll();
    },
    INCREASE_ZOOM(state) {
        state.map.setZoom(state.map.getZoom() + 1, { checkZoomRange: true });
    },
    DECREASE_ZOOM(state) {
        try {
            state.map.setZoom(state.map.getZoom() - 1, { checkZoomRange: true });
        } catch (e) {
            console.error(e.message);
        }
    },
    ZOOM_IN(state, event) {
      if (state.map && event.get('target').options.get('iconLayout') === 'default#pieChart') {
        const coords = event.get('coords');
        const zoom = state.map.getZoom() + 2;

        state.map.setCenter(coords, zoom);
      }
    }
};

const getters = {
    map: state => state.map,
    bounds: state => {
        if (!state.map) {
            return null;
        }
        return state.map.getBounds();
    },
    zoom: state => state.map.getZoom()
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
