<template>
  <div class="card-description">
    <p class="card-title">Признак партнера</p>
    <p class="card-title__small" v-for='attribute in attributesList' :key="attribute.name">{{ attribute.name }}</p>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardAttributes',
  props: {
    attributes: {
      type: Array,
      required: true
    }
  },
  computed: {
    attributesList() {
      const attributeMap = {
        ws: 0,
        bs: 1,
        fs: 2,
        is: 3
      };

      // Создаем копию массива перед сортировкой
      return [...this.attributes].sort((a, b) => {
        const aKey = a.code.toLowerCase();
        const bKey = b.code.toLowerCase();
        return attributeMap[aKey] - attributeMap[bKey];
      });
    }
  }
}
</script>

<style scoped></style>
