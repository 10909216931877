import axios from '../axios';

const list = async (coordinateArea, signal) => {
  const response = await axios.post('/api/v1/map/object/list', coordinateArea, { signal });
  return response.data.items || [];
}

const search = async (query, coordinateArea, signal) => {
  const response = await axios.post(`/api/v1/map/object/search?query=${query}`, coordinateArea, { signal });
  return response.data || null;
}

const get = async (id) => {
  const response = await axios.get(`/api/v1/map/object/${id}`);
  return response.data.item || {};
}

const filter = async (area, filters, signal) => {
  const response = await axios.post('/api/v1/map/object/filter', { area, filters }, { signal });
  return response.data || null;
};

const filterById = async (id, area, signal) => {
  const response = await axios.post(`/api/v1/map/object/filter?context=${id}`, { area }, { signal });
  return response.data || null;
};

const objects = {
  list,
  search,
  get,
  filter,
  filterById
};

export default objects;
